   <template>
  <b-form @submit.prevent>
    <b-row>
      <b-col cols="12">
        <b-form-group label="编号" label-for="h-pid" label-cols-md="4">
          <b-form-input
            v-model="pringerFormData.pid"
            id="h-pid"
            placeholder="请输入编号"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-form-group label="门店" label-for="h-storeId" label-cols-md="4">
          <b-form-input
            v-model="pringerFormData.storeId"
            id="h-storeId"
            placeholder="请输入门店id"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-form-group
          label="设备名称"
          label-for="h-printerName"
          label-cols-md="4"
        >
          <b-form-input
            v-model="pringerFormData.printerName"
            id="h-printerName"
            placeholder="请输入名称"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-form-group
          label="设备品牌"
          label-for="h-printerBrand"
          label-cols-md="4"
        >
          <v-select
            v-model="pringerFormData.printerBrand"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="title"
            :options="printerBrandOptions"
            :reduce="(printerBrandOptions) => printerBrandOptions.value"
            placeholder="请选择设备品牌"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-form-group
          label="设备编号"
          label-for="h-printerId"
          label-cols-md="4"
        >
          <b-form-input
            v-model="pringerFormData.printerId"
            id="h-printerId"
            placeholder="请输入设备编号"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-form-group
          label="设备密钥"
          label-for="h-printerKey"
          label-cols-md="4"
        >
          <b-form-input
            v-model="pringerFormData.printerKey"
            id="h-printerKey"
            placeholder="请输入设备密钥"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-form-group label="宽度" label-for="h-printerWidth" label-cols-md="4">
          <b-form-input
            v-model="pringerFormData.printerWidth"
            id="h-printerWidth"
            placeholder="请输入宽度"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-form-group label="型号" label-for="h-printerSpec" label-cols-md="4">
          <b-form-input
            v-model="pringerFormData.printerSpec"
            id="h-printerSpec"
            placeholder="请输入型号"
          />
        </b-form-group>
      </b-col>
      <!-- <b-col cols="12">
        <b-form-group
          label="标签机"
          label-for="h-isLabelPrinter"
          label-cols-md="4"
        >
          <b-form-input
            v-model="pringerFormData.isLabelPrinter"
            id="h-isLabelPrinter"
            placeholder="请输入标签机"
          />
        </b-form-group>
      </b-col> -->
      <b-col cols="12">
        <div class="chek">
          <b-card-text class="mb-0" style="margin-right:6rem"> 标签机 </b-card-text>
          <b-form-checkbox
            v-model="pringerFormData.isLabelPrinter"
            value="1"
            unchecked-value="0"
            class="custom-control-primary"
            name="check-button"
            switch
          />
        </div>
      </b-col>
      <b-col cols="12" style="margin-top:1.4rem">
        <div class="chek">
          <b-card-text class="mb-0" style="margin-right:7rem"> 状态 </b-card-text>
          <b-form-checkbox
            v-model="pringerFormData.printerToggle"
            value="1"
            unchecked-value="0"
            class="custom-control-primary"
            name="check-button"
            switch
          />
        </div>
      </b-col>
      <!-- <b-col cols="12">
        <b-form-group
          label="状态"
          label-for="h-printerToggle"
          label-cols-md="4"
        >
          <b-form-input
            v-model="pringerFormData.printerToggle"
            id="h-printerToggle"
            placeholder="请输入状态"
          />
        </b-form-group>
      </b-col> -->

      <!-- submit and reset -->
      <b-col class="mt-2">
        <b-button
          type="submit"
          variant="primary"
          class="mr-1"
          @click="savePrinterSetting"
        >
          保存
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import {
  BCard,
  BCardText,
  BCol,
  BRow,
  BCardTitle,
  BCardSubTitle,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
} from "bootstrap-vue";
import vSelect from "vue-select";

export default {
  components: {
    BCard,
    BCardText,
    BCol,
    BRow,
    BCardTitle,
    BCardSubTitle,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,

    vSelect,
  },
  props: {
    formObj: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      pringerFormData: this.formObj,
      printerBrandOptions: [
        { title: "飞鹅", value: "FE" },
        { title: "易联云", value: "YLY" },
        { title: "新烨", value: "XY" },
      ],
      // pid: "", //编号
      // storeId: "", //门店id
      // printerName: "", //设备名称
      // printerBrand: "", //设备品牌
      // printerId: "", //设备编号
      // printerKey: "", //设备密钥
      // printerWidth: "", //宽度
      // printerSpec: "", //型号
      // isLabelPrinter: "", //是否标签机
      // printerToggle: "", //是否启用
    };
  },
  watch: {
    //搜索内容发生变化，重新赋值
    formObj() {
      this.pringerFormData = this.formObj;
    },
  },
  methods: {
    savePrinterSetting() {
      console.log(this.pringerFormData);
      const printerParams = {};
      printerParams.pid = this.pringerFormData.pid;
      printerParams.storeId = this.pringerFormData.storeId;
      printerParams.printerName = this.pringerFormData.printerName;
      printerParams.printerBrand = this.pringerFormData.printerBrand;
      printerParams.printerId = this.pringerFormData.printerId;
      printerParams.printerKey = this.pringerFormData.printerKey;
      printerParams.printerWidth = this.pringerFormData.printerWidth;
      printerParams.printerSpec = this.pringerFormData.printerSpec;
      printerParams.isLabelPrinter = this.pringerFormData.isLabelPrinter;
      printerParams.printerToggle = this.pringerFormData.printerToggle;
      this.$emit("savePrinterSetting", printerParams);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.chek {
  width: 100%;
  display: flex;
}
</style>

