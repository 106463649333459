<template>
  <div>
    <PrinterSearch @handlesearchStore="handlesearchStore"></PrinterSearch>
    <b-row class="match-height" v-if="printerItems.length > 0">
      <b-col md="8">
        <b-card>
          <b-card-title>设备列表</b-card-title>
          <b-card-sub-title>点击图标修改</b-card-sub-title>
          <div style="height: 1rem"></div>
          <MyTable
            :fields="printerFields"
            :isShowHeader="false"
            :items="printerItems"
            @handleEditRowData="handleEditRowData"
          ></MyTable>
        </b-card>
      </b-col>
      <b-col md="4" v-if="printerObj">
        <b-card>
          <b-card-title>设备详情</b-card-title>
          <b-card-sub-title>请核对信息再提交</b-card-sub-title>
          <div style="height: 1rem"></div>
          <PrinterForm
            :formObj="printerObj"
            @savePrinterSetting="savePrinterSetting"
          ></PrinterForm>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BCol,
  BRow,
  BCardTitle,
  BCardSubTitle,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
} from "bootstrap-vue";
import MyTable from "@/components/MyTable.vue";

import PrinterSearch from "./childComps/PrinterSearch.vue";
import PrinterForm from "./childComps/PrinterForm.vue";

import Ripple from "vue-ripple-directive";

import { getUserInfo } from "@/utils/api";
import { changeTips } from "@/utils/util";

export default {
  components: {
    BCard,
    BCardText,
    BCol,
    BRow,
    BCardTitle,
    BCardSubTitle,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,

    MyTable,
    PrinterSearch,
    PrinterForm,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      printerFields: [
        {
          key: "pid",
          label: "编号",
        },
        {
          key: "storeId",
          label: "门店",
        },
        {
          key: "printerName",
          label: "设备名称",
        },
        {
          key: "printerBrandCH",
          label: "设备品牌",
        },
        {
          key: "printerId",
          label: "设备编号",
        },
        {
          key: "printerKey",
          label: "设备密钥",
        },
        {
          key: "printerWidth",
          label: "宽度",
        },
        {
          key: "printerSpec",
          label: "型号",
        },
        {
          key: "isLabelPrinter",
          label: "标签机",
        },
        {
          key: "printerToggle",
          label: "状态",
        },
        {
          key: "action",
          label: "操作",
        },
      ],
      printerItems: [],
      printerObj: null,
    };
  },
  created() {
    // this.getPrinter();
  },
  methods: {
    //搜索
    handlesearchStore(params) {
      console.log(params, "传递的参数");
      getUserInfo("sysAdminWeb.printerList", params).then((res) => {
        if (res && res.data && res.data.code === "200") {
          console.log(res, "搜索");
          this.printerItems = res.data.data;
          this.printerItems.forEach((it) => {
            if (it.printerBrand === "FE") {
              it.printerBrandCH = "飞鹅";
            } else if (it.printerBrand === "YLY") {
              it.printerBrandCH = "易联云";
            } else if (it.printerBrand === "XY") {
              it.printerBrandCH = "新烨";
            } else {
              it.printerBrandCH = "未知";
            }
          });
        } else {
          return changeTips(res.data.msg, "warning");
        }
      });
    },
    //点击编辑图标
    handleEditRowData(e) {
      this.printerObj = e;
    },
    //更新配置信息
    savePrinterSetting(e) {
      console.log(e, "提交的表单数据");
      getUserInfo("sysAdminWeb.printerUpdate", e).then((res) => {
        if (res && res.data && res.data.code === "200") {
          // console.log(res);
          const params = {}
          params.storeId = e.storeId
          this.handlesearchStore(params);
          this.printerObj = null
          return changeTips(res.data.msg, "primary");
        } else {
          return changeTips(res.data.msg, "warning");
        }
      });
    },


  },
};
</script>

<style>
</style>
