<template>
  <b-row>
    <b-col md="12" xl="12">
      <b-card>
      <b-input-group>
        <b-input-group-prepend>
          <b-button variant="outline-primary">
            <feather-icon icon="SearchIcon" />
          </b-button>
        </b-input-group-prepend>
        <b-form-input placeholder="请输入门店id" v-model="params.storeId" />
        <b-input-group-append>
          <b-button variant="outline-primary" @click="searchStore">
            搜索
          </b-button>
        </b-input-group-append>
      </b-input-group>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { BCard, BInputGroup, BFormInput, BInputGroupAppend, BInputGroupPrepend, BRow, BCol, BButton} from "bootstrap-vue";

export default {
  components: {
    BCard,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BRow,
    BCol,
    BButton,
  },
  data() {
    return {
      params: {
      storeId: '',
      }
    }
  },
  methods: {
    searchStore() {
      this.$emit('handlesearchStore',this.params)
    }
  }
};
</script>

<style>
</style>
